const constraints = {
  firstName: {
    presence: {
      message: 'Ingresa tus nombres',
      allowEmpty: false,
    },
  },
  lastName: {
    presence: {
      message: 'Ingresa tus apellidos',
      allowEmpty: false,
    },
  },
  phone: {
    presence: {
      message: 'Ingresa tu celular',
      allowEmpty: false,
    },
  },
  email: {
    presence: {
      message: 'Ingresa un correo',
      allowEmpty: false,
    },
    email: {
      message: 'Ingresa un correo válido',
    },
  },
  code: {
    presence: {
      message: 'Ingresa un código',
      allowEmpty: false,
    },
  },
  password: {
    presence: {
      message: 'Ingresa una contraseña',
      allowEmpty: false,
    },
  },
  newPassword: {
    presence: {
      message: 'Ingresa una contraseña',
      allowEmpty: false,
    },
    length: {
      minimum: 6,
      message: 'Ingresa un mínimo de 6 caracteres',
    },
  },
  confirmPassword: {
    length: {
      minimum: 6,
      message: 'Ingresa un mínimo de 6 caracteres',
    },
    presence: {
      message: 'Confirma la contraseña',
      allowEmpty: false,
    },
    equality: {
      attribute: 'newPassword',
      message: 'Las contraseñas no son iguales',
    },
  },
  dedicationMessage: (settings?: Record<string, never>) => {
    const constraint: Record<string, unknown> = {
      presence: {
        message: 'Ingresa una dedicatoria',
        allowEmpty: false,
      },
    }

    const MAX_DEDICATION_MESSAGE_LENGTH = 255

    constraint.length = {
      maximum: settings?.maxLength ?? MAX_DEDICATION_MESSAGE_LENGTH,
      tooLong: 'La dedicatoria solo puede tener %{count} letras',
    }

    return constraint
  },
  addressLine: {
    presence: {
      message: 'Ingresa una dirección',
      allowEmpty: false,
    },
  },
  contactPerson: {
    presence: {
      message: 'Ingresa una persona de contacto',
      allowEmpty: false,
    },
  },
  contactPhone: {
    presence: {
      message: 'Ingresa un número de contacto',
      allowEmpty: false,
    },
  },
  contactPersonFirstName: {
    presence: {
      message: 'Ingresa un valor',
      allowEmpty: false,
    },
  },
  contactPersonLastName: {
    presence: {
      message: 'Ingresa un valor',
      allowEmpty: false,
    },
  },
  contactPersonDocument: {
    presence: {
      message: 'Ingresa un valor',
      allowEmpty: false,
    },
  },
  contactPersonPhone: {
    presence: {
      message: 'Ingresa un valor',
      allowEmpty: false,
    },
  },
  contactPersonTransportUnit: {
    presence: {
      message: 'Ingresa un valor',
      allowEmpty: false,
    },
  },
  deliveryDate: {
    presence: {
      message: 'Ingresa una fecha',
      allowEmpty: false,
    },
  },
  deliveryTimeRangeId: {
    presence: {
      message: 'Ingresa un horario',
      allowEmpty: false,
    },
  },
  deliveryAddressId: {
    presence: {
      message: 'Selecciona una dirección',
      allowEmpty: false,
    },
  },
  shipmentFareId: {
    presence: {
      message: 'Selecciona un método de envío',
      allowEmpty: false,
    },
  },
  storeId: {
    presence: {
      message: 'Selecciona una tienda',
      allowEmpty: false,
    },
  },
  dni: {
    presence: {
      message: 'Ingresa un número de DNI',
      allowEmpty: false,
    },
  },
  ruc: {
    presence: {
      message: 'Ingresa un número de RUC',
      allowEmpty: false,
    },
  },
  fiscalName: {
    presence: {
      message: 'Ingresa una razón social',
      allowEmpty: false,
    },
  },
  fiscalAddress: {
    presence: {
      message: 'Ingresa una dirección fiscal',
      allowEmpty: false,
    },
  },
  lineOfBusiness: {
    presence: {
      message: 'Selecciona un giro de negocio',
      allowEmpty: false,
    },
  },
  documentId: {
    presence: {
      message: 'Selecciona un documento',
      allowEmpty: false,
    },
  },
  paymentMethodId: {
    presence: {
      message: 'Selecciona un método de pago',
      allowEmpty: false,
    },
  },
  localityId: {
    presence: {
      message: 'Selecciona una ubicación',
      allowEmpty: false,
    },
  },
  documentNumber: {
    presence: {
      message: 'Ingrese un número de documento',
      allowEmpty: false,
    },
  },
  documentTypeId: {
    presence: {
      message: 'Selecciona un tipo de documento',
      allowEmpty: false,
    },
  },
  googleAddressLine: {
    presence: {
      message: 'Ingresa una dirección',
      allowEmpty: false,
    },
  },
  reference: {
    presence: {
      message: 'Ingresa una referencia',
      allowEmpty: false,
    },
  },
  contactDocumentNumber: {
    presence: {
      message: 'Ingresa el número de documento del contacto',
      allowEmpty: false,
    },
  },
  contactEmail: {
    presence: {
      message: 'Ingresa un correo de contacto',
      allowEmpty: false,
    },
    email: {
      message: 'Ingresa un correo válido',
    },
  },
  coordinates: {
    presence: true,
  },
  zipCode: {
    presence: {
      message: 'Ingresa un código postal',
      allowEmpty: false,
    },
  },
  apartment: {
    presence: {
      message: 'Ingresa un Interior / Oficina / Piso',
      allowEmpty: false,
    },
  },
}

export default constraints
