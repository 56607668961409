import gql from 'graphql-tag'

const checkoutFragment = gql`
  fragment checkout on Order {
    id
    code
    dedicationMessage
    total
    extraFields
    comments
    status {
      id
      slug
    }
    deliveryAddress {
      id
    }
    store {
      id
    }
    deliveryDate
    deliveryTimeRange {
      id
    }
    document {
      id
      documentType {
        id
        slug
      }
    }
    shippingType {
      id
      slug
    }
    contactPersonFirstName
    contactPersonLastName
    contactPersonDocument
    contactPersonPhone
    contactPersonTransportUnit
  }
`

export default checkoutFragment
