import { head, mapValues } from 'lodash'
import validate from 'validate.js'

import * as formConstraints from './form-constraints'

const config: validate.ValidateOption = {
  fullMessages: false,
}

export const getValidator = (constraints?: Record<string, unknown>) => {
  return (values: Record<string, unknown>) => {
    const errors = validate(values, constraints, config)

    return mapValues(errors, head)
  }
}

export const getSingleValidator = (constraints: Record<string, unknown>) => {
  return (value: any) => {
    return validate.single(value, constraints)
  }
}

const { signupToSeePrices } = formConstraints

const signupToSeePricesValidators = {
  userInfo: getValidator(signupToSeePrices.userInfo),
  deliveryAddress: getValidator(signupToSeePrices.deliveryAddress),
  fiscalAddress: getValidator(signupToSeePrices.fiscalAddress),
  document: getValidator(signupToSeePrices.document),
}

export default {
  signupToSeePricesValidators,
  birthdateField: getValidator(formConstraints.birthdateField),
  purchaseOrderNumberField: getValidator(formConstraints.purchaseOrderNumberField),
  privacyPolicyAccepted: getValidator(formConstraints.privacyPolicyAcceptedField),
  publicLogin: getValidator(formConstraints.publicLogin),
  privateLogin: getValidator(formConstraints.privateLogin),
  signup: getValidator(formConstraints.signup),
  changePassword: getValidator(formConstraints.changePassword),
  account: getValidator(formConstraints.account),
  resetPassword: getValidator(formConstraints.resetPassword),
  deliveryAddress: getValidator(formConstraints.deliveryAddress),
  payment: getValidator(formConstraints.payment),
  customerComplaint: getValidator(formConstraints.customerComplaint),
  purchaseChannel: getValidator(formConstraints.purchaseChannel),
  card: getValidator(formConstraints.card),
  culqiForm: getValidator(formConstraints.culqiForm),
  culqiYapeForm: getValidator(formConstraints.culqiYapeForm),
  deposit: (formConfig: { transactionCode: boolean; voucherUrl: boolean }) => {
    const constraints = {
      transactionCode: formConfig.transactionCode ? formConstraints.depositForm.transactionCode : undefined,
      voucherUrl: formConfig.voucherUrl ? formConstraints.depositForm.voucherUrl : undefined,
    }

    return getValidator(constraints)
  },
}
