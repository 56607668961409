import gql from 'graphql-tag'

import { vendorFragment } from '../fragments'

const appConfigQuery = gql`
  query vendor {
    vendor {
      ...vendor
    }
    manufacturer {
      id
      originalId
      name
      slug
      endpoint
      features {
        slug
        settings
      }
      settings
    }
    user {
      id
      isLoggedIn @client
      vendorCode
      originalId
      fullName
      phone
      email
      role {
        id
        slug
      }
      quotationsPermission
    }
  }
  ${vendorFragment}
`

export default appConfigQuery
