import React, { useRef, useState } from 'react'

import { useVendor } from '~/hooks'
import Image from '~/truck/image'
import Loader from '~/truck/loader'
import notifier from '~/truck/notifier'
import { baseUrls } from '~/utils/constants'

interface PaymentVoucherProps {
  onSuccess: (url: string) => void
}

const urls = {
  attach: 'v1574356420/sellers/resources/attach.png',
  see: 'v1574365524/sellers/resources/view.png',
}

const url = `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUD_NAME}/image/upload`

const attachImage = `${baseUrls.CLOUDINARY}/${urls.attach}`

const viewVoucher = `${baseUrls.CLOUDINARY}/${urls.see}`

function PaymentVoucherFile(props: PaymentVoucherProps) {
  const { onSuccess } = props

  const vendor = useVendor()

  const inputRef = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState(false)

  const [state, setState] = useState({
    voucherName: null,
    voucherUrl: null,
  })

  const onFileInputChange = async e => {
    setLoading(true)

    const [file] = e.target.files

    const body = new FormData()

    body.append('upload_preset', 'vouchers')

    body.append('file', file)

    body.append('folder', `sellers/${vendor.slug}/vouchers`)

    const config = { body, method: 'post' }

    try {
      const res = await fetch(url, config)

      const data = await res.json()

      setState({
        voucherName: file.name,
        voucherUrl: data.secure_url,
      })

      onSuccess(data.secure_url)
    } catch (error) {
      notifier.error('Hubo un error al subir el comprobante de pago, intente nuevamente')
    }

    setLoading(false)
  }

  const handleOnClick = () => {
    if (loading) {
      return
    }

    inputRef.current.click()
  }

  return (
    <>
      <div className="mt-2 flex h-12 cursor-pointer border border-dashed p-1">
        <div className="flex flex-1 items-center" onClick={handleOnClick}>
          {loading ? (
            <div className="flex flex-1 items-center justify-between px-2 py-4">
              <p className="text-xs font-normal text-blue-500">Subiendo archivo ...</p>
              <Loader className="ml-auto !h-0 !w-auto" />
            </div>
          ) : (
            <>
              <Image src={attachImage} />
              <p className="ml-2 text-xs font-normal text-blue-500">
                {state.voucherName || 'Adjuntar el comprobante de pago'}
              </p>
            </>
          )}
        </div>
        {state.voucherUrl && (
          <a className="ml-auto p-3" href={state.voucherUrl} target="_blank" rel="noreferrer">
            <Image src={viewVoucher} />
          </a>
        )}
      </div>

      <input
        ref={inputRef}
        accept="application/pdf,image/png,image/jpg,image/jpeg"
        type="file"
        className="hidden"
        onChange={onFileInputChange}
      />
    </>
  )
}

export default PaymentVoucherFile
