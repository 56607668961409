import { includes, isNil, isNumber, pick } from 'lodash'

import { documentTypes } from '~/utils/constants'
import constraints from '~/utils/constraints'

export const publicLogin = pick(constraints, ['email', 'password'])

export const privateLogin = pick(constraints, ['code', 'password'])

export const changePassword = pick(constraints, ['newPassword', 'confirmPassword'])

export const resetPassword = pick(constraints, ['email'])

export const payment = pick(constraints, ['paymentMethodId'])

export const signup = pick(constraints, ['firstName', 'lastName', 'phone', 'email', 'password'])

export const account = pick(constraints, ['firstName', 'lastName', 'phone', 'email'])

export const deliveryAddress = pick(constraints, ['addressLine', 'contactPerson', 'contactPhone', 'localityId'])

const underAgeRequiredField = (_: unknown, values: Record<string, never>) => {
  if (!values.isUnderage) {
    return null
  }

  return {
    presence: {
      message: 'Este campo es requerido si usted es menor de edad',
    },
  }
}

export const purchaseChannel = {
  purchaseChannel: {
    presence: {
      message: 'Seleccione un medio de compra',
    },
  },
}

export const customerComplaint = {
  ...pick(constraints, ['firstName', 'lastName', 'email', 'phone', 'localityId']),
  documentType: {
    presence: {
      message: 'Seleccione un tipo de documento',
    },
  },
  documentNumber: {
    presence: {
      message: 'Ingrese un número de documento',
    },
  },
  addressLine: {
    presence: {
      message: 'Ingrese una dirección',
    },
  },
  claimedAt: {
    presence: {
      message: 'Ingrese la fecha del reclamo o queja',
    },
  },
  claimedAmount: {
    presence: {
      message: 'Ingrese el monto reclamado',
    },
  },
  assetDescription: {
    presence: {
      message: 'Ingrese la descripción del bien contratado',
    },
  },
  details: {
    presence: {
      message: 'Ingrese el detalle del reclamo o queja',
    },
  },
  customerRequest: {
    presence: {
      message: 'Ingrese su pedido como reclamante',
    },
  },
  termsAccepted: {
    presence: {
      message: 'Debe aceptar los términos de su reclamo',
    },
  },
  reCaptchaToken: {
    presence: {
      message: 'Este campo es requerido',
    },
  },
  parentFirstName: underAgeRequiredField,
  parentLastName: underAgeRequiredField,
  parentEmail: (_: unknown, values: Record<string, never>) => {
    if (!values.isUnderage) {
      return null
    }

    return {
      email: {
        message: 'Ingresa un correo válido',
      },
      presence: {
        message: 'Este campo es requerido si usted es menor de edad',
      },
    }
  },
  parentPhone: underAgeRequiredField,
  parentDocumentType: underAgeRequiredField,
  parentDocumentNumber: underAgeRequiredField,
}

export const signupToSeePrices = {
  userInfo: {
    email: constraints.email,
    password: {
      ...constraints.password,
      length: {
        minimum: 6,
        message: 'Debe contener como mínimo 6 caracteres',
      },
    },
    confirmPassword: {
      presence: {
        message: 'Confirma la contraseña',
      },
      equality: {
        attribute: 'password',
        message: 'Las contraseñas no son iguales',
      },
    },
    firstName: {
      ...constraints.firstName,
      length: {
        minimum: 3,
        message: 'Debe contener como mínimo 3 caracteres',
      },
    },
    lastName: {
      ...constraints.lastName,
      length: {
        minimum: 3,
        message: 'Debe contener como mínimo 3 caracteres',
      },
    },
    phone: {
      ...constraints.phone,
      length: {
        minimum: 7,
        message: 'Debe de contener como mínimo 7 dígitos, incluir el código de la ciudad',
      },
    },
  },
  deliveryAddress: {
    line: {
      ...constraints.addressLine,
      length: {
        minimum: 10,
        message: 'Debe de contener como mínimo 10 caracteres',
      },
    },
    localityId: constraints.localityId,
  },
  fiscalAddress: {
    fiscalName: {
      presence: {
        message: 'Ingresa tu razón social',
      },
    },
    line: {
      ...constraints.addressLine,
      length: {
        minimum: 10,
        message: 'Debe de contener como mínimo 10 caracteres',
      },
    },
    localityId: constraints.localityId,
  },
  document: {
    documentTypeIdWithSlug: constraints.documentTypeId,
    documentNumber: (_: unknown, values: Record<string, never>) => {
      const { documentTypeIdWithSlug } = values

      const isTicket = includes(documentTypeIdWithSlug, documentTypes.TICKET)

      if (isTicket) {
        return {
          presence: {
            message: 'Ingrese su número de DNI',
          },
          length: {
            is: 8,
            message: 'Debe de contener 8 dígitos',
          },
        }
      }

      return {
        presence: {
          message: 'Ingrese su número de RUC',
        },
        length: {
          is: 11,
          message: 'Debe de contener 11 dígitos',
        },
      }
    },
  },
}

export const card = {
  ownerName: {
    presence: {
      message: 'Ingrese el nombre del titular',
    },
  },
  cardNumber: {
    presence: {
      message: 'Ingrese el número de la tarjeta',
    },
  },
  month: {
    presence: {
      message: 'Ingrese el mes de expiración',
    },
  },
  year: {
    presence: {
      message: 'Ingrese el año de expiración',
    },
  },
  securityCode: {
    presence: {
      message: 'Ingrese el código de seguridad',
    },
  },
}

export const depositForm = {
  transactionCode: {
    presence: {
      message: 'Ingrese el código de la transacción',
    },
  },
  voucherUrl: {
    presence: {
      message: 'El comprobante es requerido',
    },
  },
}

export const culqiYapeForm = {
  phone: {
    presence: {
      message: 'Ingrese el numero de telefono',
    },
  },
  code: {
    presence: {
      message: 'Ingrese el código de seguridad',
    },
  },
}

export const culqiForm = {
  ...card,
  email: {
    presence: {
      message: 'Ingrese el correo electrónico',
    },
  },
}

export const birthdateField = {
  birthdate: (_: unknown, attributes: Record<string, any>) => {
    const { settings, isRequired = true } = attributes

    if (!settings) {
      return false
    }

    const ownConstraints: Record<string, unknown> = {
      message: 'La fecha ingresada es incorrecta',
    }

    if (settings?.legalAge) {
      const { legalAge } = settings

      ownConstraints.legalAge = {
        message: `Debes tener al menos ${legalAge} años`,
        value: legalAge,
      }
    }

    const constraint: any = {
      birthdate: ownConstraints,
    }

    if (isRequired) {
      constraint.presence = {
        message: 'Ingresa tu fecha de nacimiento',
      }
    }

    return constraint
  },
}

export const privacyPolicyAcceptedField = {
  privacyPolicyAccepted: (_: unknown, values: Record<string, any>) => {
    const { vendor, privacyPolicyAccepted } = values

    const { privacyPolicyUrl } = vendor

    if (!privacyPolicyUrl || privacyPolicyAccepted) {
      return false
    }

    return {
      presence: {
        message: `Debe aceptar la Política de Privacidad de ${vendor.name}`,
      },
    }
  },
}

export const purchaseOrderNumberField = {
  purchaseOrderNumber: (_: unknown, attribute: Record<string, any>) => {
    const { settings } = attribute

    if (isNil(settings)) {
      return false
    }

    const constraints: Record<string, any> = {}

    const label = settings.label.toLowerCase()

    if (settings.rules?.required) {
      constraints.presence = {
        message: `Ingresa el ${label}`,
      }
    }

    if (isNumber(settings.rules?.maxLength)) {
      constraints.length = {
        maximum: settings.rules?.maxLength,
        tooLong: `El ${label} debe de contener como máximo %{count} caracteres`,
      }
    }

    return constraints
  },
}
