import * as React from 'react'
import { useField, useForm } from 'react-final-form'

import Field from '~/truck/field'

import { PaymentVoucherFile } from '../components'

export default function DepositForm() {
  const form = useForm()

  const voucherUrlField = useField('voucherUrl')

  return (
    <div className="mt-4 rounded-lg bg-gray-100 p-6 shadow-md">
      <h2 className="mb-6 text-base font-semibold">Ingrese los detalles de la transacción</h2>
      <div className="space-y-4">
        <Field name="transactionCode" placeholder={'Codigo de transacción'} />
        <div>
          <PaymentVoucherFile
            onSuccess={voucherUrl => {
              form.change('voucherUrl', voucherUrl)
            }}
          />
          {voucherUrlField.meta.error && voucherUrlField.meta.touched && (
            <p className="ml-4 mt-1 text-xs text-red-500">{voucherUrlField.meta.error}</p>
          )}
        </div>
      </div>
    </div>
  )
}
