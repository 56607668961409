import type { IVendor } from '~/types/vendors'

import localeData from './locale.json'
import vendorData from './vendor.json'

const defaultLocale = localeData.PE

const getStrings = (vendor: IVendor) => {
  return {
    ...defaultLocale,
    ...localeData[vendor.country.code],
    ...vendorData.default,
    ...vendorData[vendor.manufacturer?.slug],
    ...vendorData[vendor.slug],
  }
}

export default getStrings
